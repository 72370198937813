import React from "react";
import style from './estilo.module.css';

export const Banner = (props) => {
    return (
        <div className={style.banner} id="home">
            <div className={style.overlay}></div>
            <div className={style.bottomFade}></div>

            <div className={style.contenidoBanner}>
                <h1 className={style.textoCentral}>Boost the excellence,</h1>
                <h1 className={style.textoCentral}>maximizes performance</h1>
                <p>
                Our strategic focus and extensive experience enable us to provide leading companies with 
                the solutions they need to thrive in today's marketplace.
                </p>
            </div>
        </div>
    );
};
