import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './social.css';

export const Social = () => {
  useEffect(() => {
    const handleScroll = () => {
      const footer = document.querySelector('.social');
      if (window.scrollY > 100) {
        footer.classList.add('visible');
      } else {
        footer.classList.remove('visible');
      }
    };
    window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <footer className="social">
      <Container>
        <img className="logo-left" src="img/icons/ojo.png" alt="Logo" />
        <div className="social-logos">
            <img src="img/icons/Facebook.png" alt="Facebook" />
            <img src="img/icons/Icon.png" alt="LinkedIn" />
          {/* Agrega más imágenes de logos de redes sociales según sea necesario */}
        </div>
      </Container>
    </footer>
  );
};