import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link as ScrollLink, Events, scrollSpy } from 'react-scroll';
import './estilo.css';
export const Navigation = () => {
  const [navbarBackground, setNavbarBackground] = useState('transparent');
  const [expanded, setExpanded] = useState(false);
  const [activeLink, setActiveLink] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos > 50) {
        setNavbarBackground('#191530');
      } else {
        setNavbarBackground('transparent');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Initialize scrollSpy
    scrollSpy.update();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Event listener for scrollSpy
    Events.scrollEvent.register('begin', function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register('end', function () {
      console.log("end", arguments);
    });

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  return (
    <Navbar expanded={expanded} onToggle={() => setExpanded(!expanded)} expand="lg" style={{ backgroundColor: navbarBackground }} sticky="top">
      <Container>
        <Navbar.Brand href="#home">
          <img src="img/icons/ojo.png" alt="Ojo" className="logo-responsive" />
          <img src="https://cardexbuckettesca.s3.us-west-2.amazonaws.com/tesca/TESCA2.png" alt="Logo" className="page-scroller" />
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <ScrollLink
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="nav-link"
              onSetActive={() => setActiveLink('Inicio')}
            >
              Home
            </ScrollLink>
            <ScrollLink
              activeClass="active"
              to="Metodology"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="nav-link"
              onSetActive={() => setActiveLink('Metodologia')}
            >
              Methodology
            </ScrollLink>
            <ScrollLink
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="nav-link"
              onSetActive={() => setActiveLink('nosotros')}
            >
              About us
            </ScrollLink>
            <ScrollLink
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="nav-link"
              onSetActive={() => setActiveLink('contact')}
            >
              Contact
            </ScrollLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
