import { useEffect, useState } from 'react';

export const useSectionScroll = (sectionIds) => {
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      let currentSection = '';
      sectionIds.forEach((id) => {
        const section = document.getElementById(id);
        const offset = section.offsetTop;
        const height = section.offsetHeight;
        const scrollTop = window.scrollY;
        
        if (scrollTop >= offset && scrollTop < offset + height) {
          currentSection = id;
        }
      });
      setActiveSection(currentSection);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sectionIds]);

  return activeSection;
};