import React from "react";
import style from './estilo.module.css';

export const Nosotros = () => {
    return (
        <>
            <div className={style.nosotros} id="about">
                <div className={style.xd}>
                    <h1>Who are we?</h1>
                    <p>
                    We are a corporation founded in 2015 that rescues the Mexica Toltec wisdom to create a unique and innovative business methodology that comprehensively covers the 4 key areas in every business.                    </p>
                    <p> We create interdepartmental harmony to have a straight and harmonious management of all our companies.</p>
                    <div className={style.rectangles}>
                        <div className={style.rectangle}>More than 8 years of experience</div>
                        <div className={style.rectar}>With fiscal addresses in Miami, Florida and Palo Alto, California.</div>
                        <div className={style.rectangle}>Customers in Argentina, Chile, France, Spain, Israel, Japan, Canada, Mexico, United States and Japan.</div>
                    </div>
                    {/* Contenedor para Misión y Visión */}
                    <div className={style.mission_vision}>
                        <div className={style.card}>
                            <h2>Mission</h2>
                            <p>To empower and unify complementary market forces, i.e. supply and demand. We promote the progress of society by being a positive influence to various industries.</p>
                        </div>
                        <div className={style.card}>
                            <h2>Vision</h2>
                            <p>To stand out as the most innovative corporate in Latin America in terms of growth and management of companies with social and human impact.</p>
                        </div>
                    </div>
                </div>
                </div>
        </>
    );
};
