import React from 'react';
import style from '../metodologia/estilo.module.css';

export const Card = ({ data }) => {
  const { title, subtitle, items, imgSrc } = data;

  const getTextColor = (subtitle) => {
    switch (subtitle) {
      case 'Expansion':
        return '#6D6D6D';  // Gris
      case 'Profitability':
        return '#DA2A2A';  // Rojo
      case 'Union':
        return '#58AEFD';  // Azul claro
      case 'Quality':
        return '#1BA048';  // Verde
      default:
        return '#000000';  // Negro por defecto
    }
  };

  const subtitleStyle = {
    color: getTextColor(subtitle),
  };

  return (
    <div className={style.cardArea}>
      <div className={style.content}>
        <h1>{title}</h1>
        <div className={style.line}></div>
        <h2 style={subtitleStyle}>{subtitle}</h2>
        <ul>
          {items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
      <div className={style.imgCard}>
        <img src={imgSrc} alt="Icono" className={style[`img-${title.toLowerCase()}`]} style={{ width: '60%', objectFit: 'cover' }} />
      </div>
    </div>
  );
};
