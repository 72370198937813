import React from "react";
import { Banner } from "./components/banner/banner";
import { Navigation } from "./components/navbar/navigation";
import { Metodologia } from "./components/metodologia/metodologia";
import { Servicios } from "./components/servis/servis";
import { Clientes } from "./components/nuclients/clientes";
import { Nosotros } from "./components/nosotros/nosotros";
import { Contacto } from "./components/contacto/contacto";
import { Footer } from "./components/footer/index";
import { Social } from "./components/footersocial/social";
import SmoothScroll from "smooth-scroll";
import 'bootstrap/dist/css/bootstrap.min.css';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <>
      <Navigation />
      <Banner />
      <Metodologia />
      <Nosotros />
      <Contacto />
      <Footer />
      <Social />
    </>
  );
};

export default App;