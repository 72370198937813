import React from 'react';
import { Card } from '../resources/card';
import style from './estilo.module.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

const data = [
    {
        title: 'Earth',
        subtitle: 'Quality',
        items: ['Operations', 'Logistics', 'Processes', 'Continuous improvement'],
        imgSrc: 'img/focos/tierra.png',
    },
    {
        title: 'Water',
        subtitle: 'Union',
        items: ['Human Resources', 'Legal'],
        imgSrc: 'img/focos/agua.png',
    },
    {
        title: 'Fire',
        subtitle: 'Profitability',
        items: ['Accounting', 'Finance'],
        imgSrc: 'img/focos/fuego.png',
    },
    {
        title: 'Air',
        subtitle: 'Expansion',
        items: ['Marketing', 'Sales'],
        imgSrc: 'img/focos/tornado.png',
    },
];

export const Metodologia = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <div className={style.Metodologia} id="Metodology">
            <div className={style.info1}>
                <h1>Business Methodology</h1>
                <p>Our unique methodology based on the four elements - Earth, Water, Fire and Air - is the axis that boost growth and problem solving in your company. Every business 
                   needs a perfect harmony of these elements to thrive.
                </p>
                <p>
                    Any business failure is directly related to a poor understanding of any of these 4 elements working in perfect harmony.
                    any of these 4 elements working in perfect harmony.
                </p>
            </div>
            <div className={style.cards}>
                <Slider className={style.carrussel} {...settings}>
                    {data.map((item, index) => (
                        <Card key={index} data={item} />
                    ))}
                </Slider>
            </div>
            <div className={style.info2}>
                <p>
                By combining these 4 elements, our methodology creates a perfect balance, ensuring that every aspect of your business is addressed. We provide you with a panoramic and detailed view of your operations, offering you comprehensive and tailored solutions to overcome any challenge.                </p>
                <p>
                With Tesca Elements you will be armed with a strategy that will allow you to confidently navigate any business scenario. Request your free consultation today and find out how we can help you achieve your business goals.                </p>
            </div>
        </div>
    );
};
