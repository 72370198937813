import React from "react";
import { useState } from "react";
import emailjs from "emailjs-com";
import style from './style.module.css';

const initialState = {
    name: '',
    email: '',
    message: '',
    Telefono: '',
};

export const Contacto = () => {
    const [{ name, email, message, Telefono }, setState] = useState(initialState);
    const [showNotification, setShowNotification] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => {
        setState(initialState);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        clearState();
        emailjs
            .sendForm("service_tesca", "template_bbrne8a", e.target, "gsQBhP0rub6i6-Y-6")
            .then(
                (result) => {
                    console.log(result.text);
                    setShowNotification(true);
                    setTimeout(() => {
                        setShowNotification(false);
                    }, 5000);

                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <div className={style.contacto} id="contact">
                <div className={style.titulo}>
                    <h1>YO SOY TÚ, TU ERES YO ¡TESCA!</h1>
                    <p>We offer free and personalized advice.</p>
                    <p>Fill out our form to schedule an appointment and we will be happy to answer all your questions.</p>
                </div>
                <div className={style.prueba}>
                    <div className={style.formulario}>
                        {showNotification ? (
                            <div className={style.enviado}>
                                <h2>Thank you!</h2>
                                <p>Thank you for sending your information, one of our advisors will contact you shortly.</p>
                            </div>
                        ) : (
                            <form name="sentMessage" onSubmit={handleSubmit}>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Name"
                                    required
                                    value={name}
                                    onChange={handleChange}
                                />

                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Email"
                                    required
                                    value={email}
                                    onChange={handleChange}
                                />

                                <input
                                    type="tel"
                                    id="Telefono"
                                    name="Telefono"
                                    placeholder="Phone"
                                    required
                                    value={Telefono}
                                    onChange={handleChange}
                                />

                                <textarea
                                    id="message"
                                    name="message"
                                    placeholder="Enter your query"
                                    required
                                    value={message}
                                    onChange={handleChange}
                                ></textarea>

                                <button type="submit">Send your request</button>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};


