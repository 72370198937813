import React, { useRef, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useSectionScroll } from './useSectionScroll'; // Asume que el hook está en este archivo
import './footer.css'; // Asume que el CSS actualizado está aquí

const sectionIds = ['home', 'Metodology', 'about', 'contact'];

export const Footer = () => {
  const activeSection = useSectionScroll(sectionIds);
  const sectionAngles = {
    home: 0,
    Metodology: 60,
    about: 120,
    contact: 180,
  };

  const circleRef = useRef(null);

  useEffect(() => {
    const circle = circleRef.current;
    const circleStyle = {
      transform: `rotate(${sectionAngles[activeSection] || 0}deg)`,
    };
    circle.style.transform = circleStyle.transform;
  }, [activeSection, sectionAngles]);

  const circleStyle = {
    transform: `rotate(${sectionAngles[activeSection] || 0}deg)`,
    transition: 'transform 0.5s ease',
  };

  return (
    <footer className="footer">
      <Container>
        <div className="square-container">
          <div className="circular-menu" ref={circleRef} style={circleStyle}>
            <div className="nav-items">
              {sectionIds.map((id) => (
                <a
                  key={id}
                  href={`#${id}`}
                  className={`footer-link ${activeSection === id ? 'active' : ''} ${activeSection && activeSection !== id ? 'inactive' : ''}`}
                >
                  {id.charAt(0).toUpperCase() + id.slice(1)}
                </a>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};
